import './src/styles/global.css'
import './src/styles/fonts.css'
import './src/styles/custom.css'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'lazysizes'

import React from "react"
import { StoreProvider } from "./src/context/store"


export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)
