import React, { createContext, useState, useEffect, useContext } from 'react';

const defaultValues = {
  loading: false,
  searchIsOpen: false,
  searchTerm: ''
}

export const StoreContext = createContext(defaultValues)

export const StoreProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [searchIsOpen, setSearchIsOpen] = useState(defaultValues.searchIsOpen);
  const [searchTerm, setSearchTerm] = useState(defaultValues.searchTerm);


  const openSearch = () => {
    setSearchIsOpen(true);
  }
  const closeSearch = () => {
    setSearchIsOpen(false);
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        openSearch,
        closeSearch,
        searchTerm,
        setSearchTerm,
        searchIsOpen,
        loading

      }}
    >
      {children}
    </StoreContext.Provider>
  )
}